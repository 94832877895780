import React from 'react';
import type { FC } from 'react';

import { Heading, Link, Stack } from '@chakra-ui/core';

import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import { withSEO } from 'components/_hoc';
import { ButtonGatsbyLink, ButtonChakraLink, Emoji } from 'components/_lib';

/**
 * App root page
 */
const RootPage: FC = () => (
  <Stack w="100vw" h="100vh" align="center" justify="center" spacing={8} p={8}>
    <Link href={process.env.LANDING_PAGE_URL} w="125px">
      <EinsteinStudiosLogoUpright />
    </Link>
    <Stack
      spacing={6}
      color="teal.500"
      align="center"
      textAlign="center"
      justify="center"
      maxW="90%"
    >
      <Stack>
        <Heading size="md">
          Welcome to the Einstein Studios web platform!{' '}
          <Emoji symbol="👋" label="waving hand" />
          <Emoji symbol="👋" label="waving hand" />
          <Emoji symbol="👋" label="waving hand" />
        </Heading>
        <Heading size="md">Log in to get started:</Heading>
      </Stack>
      <ButtonGatsbyLink to="/login/parent" size="lg" w="275px">
        Log in as parent
      </ButtonGatsbyLink>
      <ButtonGatsbyLink to="/login/teacher" size="lg" w="275px">
        Log in as teacher
      </ButtonGatsbyLink>
      <ButtonChakraLink
        href={process.env.ADMIN_PLATFORM_URL}
        size="lg"
        w="275px"
      >
        Log in as admin
      </ButtonChakraLink>
    </Stack>
  </Stack>
);

export default withSEO(RootPage, {
  title: 'Welcome!',
  description:
    'Log in to the Einstein Studios web platform as either a parent, teacher, or administrator.',
});
